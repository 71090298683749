import { charsCounter } from "./components/chars-counter";

import Rails from "@rails/ujs";
Rails.start();
// require("@rails/activestorage").start();
// import "./channels";
import "./controllers";

import "@kollegorna/cocoon-vanilla-js";
import "bootstrap";
import "dropzone/dist/dropzone.css";
import "video.js";
import "video.js/dist/video-js.min.css";
import "@videojs/themes/dist/sea/index.css";
// import "../plugins/flatpickr";

import "./components/pwa";
import { toggleNavbar, toggleAnimatedBtn } from "./components/navbar";
import { loginAnimation } from "./components/login-animation";
import { avatarUploader } from "./components/avatar-uploader";
import { toggleRoleCard } from "./components/role-selector";
// import { initAutocomplete } from "./plugins/init_autocomplete";
import { toggleBankAccount } from "./components/bank-selector";
import { checkContacted } from "./components/check-contacted";
import { initBubble } from "./components/range";
import { initStarRating } from "./plugins/init_star_rating";
import { openModal } from "./components/sign-in-modal";
import { copyText } from "./components/copy-text";
import { fileForms } from "./components/pdf-input";
import { initInputMask } from "./plugins/input_mask";
import { detectBatchForm } from "./components/batch-form";
import { disableSubmitBtn } from "./components/disable-form-btn";
import { calculateCounterofferPrice } from "./components/price-counteroffer";

toggleNavbar();
toggleAnimatedBtn();
loginAnimation();
avatarUploader();
toggleRoleCard();
// initAutocomplete();
toggleBankAccount();
checkContacted();
initStarRating();
initBubble();
openModal();
copyText();
fileForms();
initInputMask();
detectBatchForm();
disableSubmitBtn();
calculateCounterofferPrice();
charsCounter();
