import "bootstrap-star-rating";
import "bootstrap-star-rating/css/star-rating.min.css";

const initStarRating = () => {
  $("#review_rating").rating({
    min: 0,
    max: 5,
    step: 1,
    size: "md",
    filledStar: '<i class="fa-solid fa-star"></i>',
    emptyStar: '<i class="fa-regular fa-star"></i>',
    clearButton: '<i class="fa-solid fa-circle-minus"></i>',
    showClear: false,
    showCaption: false,
    animate: false,
    containerClass: "text-primary",
  });
};

export { initStarRating };
