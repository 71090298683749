import { Controller } from "@hotwired/stimulus";
import * as flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import "flatpickr/dist/flatpickr.min.css";

export default class extends Controller {
  connect() {
    flatpickr(".datepicker", {
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "D d-M",
      minDate: new Date().fp_incr(this.data.get("minDate")) || "today",
      maxDate: this.data.get("maxDate") || new Date().fp_incr(15),
      enableTime: false,
      locale: Spanish,
      disableMobile: "true",
      defaultDate: new Date(),
    });
  }
}
